import { ICreatedVideosSearch, IEventAttributes, InboxSearch, IPostAttributes, IPostUrl, IUserAttributes, PostStatus, Role, ICreatedPost } from "@emovid/payloads-library";
import moment from "moment";
import { useQuery, UseQueryOptions } from "react-query";
import TagManager from "react-gtm-module";
import { matchPath } from "react-router";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import { H } from "highlight.run";
import { Device } from "@capacitor/device";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { isDesktop, isTablet } from "react-device-detect";
import { NavType } from "../Components/Nav/NavBar";
import HttpService from "../Services/HttpService";
import { Paths } from "./AppRoutes";
import { API_KEY, APP_TITLE, BACKEND_URL, DOMAIN_URL, EMAIL_REGEX, MAX_FILE_SIZE_IN_MB } from "./Constants";
import { SECURE_IMAGE_URL, SHOULD_SHOW_REACTION_ON_LOGIN, SHOULD_WHITELIST_USER_AFTER_LOGIN_KEY, USE_BTN_ON_REACTION_LOGIN } from "./Constants";
import { AuthenticityType, IPostResponseAttributes } from "../Interfaces/IPostResponseAttributes";
import { EmailRecipientTrialExpiredUserStorageKey, EmailRecipientUserDetailsStorageKey } from "../Components/ViewEmovid/ViewEmovidConstants";
import { IUserInfo } from "../Interfaces/IUserInfo";
import ToastService from "./ToastService";
import { AuthData } from "../Contexts/AuthProvider";
import { IEventInfoResponse } from "../Interfaces/IEventInoResponse";

export const NAV_HEIGHTS = {
    mobile: { trial: 116, trial_without_button: 100, normal: 64 },
    desktop: { trial: 126, trial_without_button: 114, normal: 70 }
};
export const LogValues = (positionMessage: string, additionalParams: any = {}) => {
    if (["test", "devops"].includes(process.env.REACT_APP_ENVIRONMENT || "")) {
        console.log("Start ---------------------------------------------------");
        console.log("Message: " + positionMessage);
        console.log("Additional params", JSON.stringify(additionalParams));
        // console.log("userData", localStorage.getItem("userData"));
        console.log("End ---------------------------------------------------");
    }
};
export const userAttributesFromAuthContext = (user?: IUserInfo): IUserAttributes => {
    return {
        id: user?.userId,
        name: user?.name,
        email: user?.email || "",
        role: Role.USER
    };
};
export const userAttributesFromUserId = (userId?: string): IUserAttributes => {
    return {
        id: userId,
        name: "",
        email: "",
        role: Role.USER
    };
};
export const toSentence = (arr: string[]) => {
    return arr.join(", ").replace(/,\s([^,]+)$/, " and $1");
};
export const getEventById = async (id: string): Promise<IEventInfoResponse> => {
    return HttpService.getWithAPIKey(`/v1/event/${id}`).then(response => response);
};
export const updateEventById = async (eventId: string, data: any) => {
    return HttpService.postFormData(`/v1/event/${eventId}/update`, data, false, true).then(response => response);
};
export const getPostById = async (id: string, loggedInUser = false) => {
    let promise = loggedInUser ? HttpService.get(`/v1/post/${id}`) : HttpService.getWithAPIKey(`/v1/post/${id}`);
    return promise.then(response => response.post);
};
export const getViewsForPost = async (postId: string, loggedInUser = false) => {
    let promise = loggedInUser ? HttpService.get(`/v1/post/${postId}/views`) : HttpService.getWithAPIKey(`/v1/post/${postId}/views`);
    return promise.then(response => response.views);
};
export const getThreadsForPostIds = async (userId: string, postIds: string[]) => {
    return HttpService.postFormData(`/v1/post/threads`, { user_id: userId, post_ids: postIds }, false, true).then(response => response.threads);
};
export const getEmailTrackingInfoForPost = async (postId: string) => {
    return HttpService.get(`/v1/post/${postId}/email/tracking/info`).then(response => response.email_tracking_info);
};
export const updatePostById = async (postId: string, data: any) => {
    return HttpService.postFormData(`/v1/post/${postId}/update`, data, false, true).then(response => response);
};
export const postReaction = async (postId: string, userId: string, reactionNameType: string) => {
    return HttpService.postFormData(`/v1/post/${postId}/reaction`, { user_id: userId, reaction_name: reactionNameType }, false, true).then(response => response);
};
export const postShared = async (userId: string, postId: string, duration: number, shareMedium: string) => {
    return HttpService.postFormData(`/v1/user/shared/post`, { post_id: postId, user_id: userId, time_saved: duration, share_medium: shareMedium }, false, true).then(
        response => response
    );
};
export const getSuggestedContacts = async (userId: string, eventId: string) => {
    return HttpService.getWithAPIKey(`/v1/suggested/list/${userId}/${eventId}`).then(response => response.suggestions);
};
export const getPostsForEvent = async (id: string, previewPostId: string, userId: string = "", loggedInUser = false) => {
    let queryParams: string[] = [];
    if (previewPostId) queryParams.push(`previewPostId=${previewPostId}`);
    if (userId) queryParams.push(`userId=${userId}`);
    let postsPromise = loggedInUser
        ? HttpService.get(`/v1/event/${id}/posts${queryParams.length > 0 ? `?${queryParams.join("&")}` : ""}`)
        : HttpService.getWithAPIKey(`/v1/event/${id}/posts${queryParams.length > 0 ? `?${queryParams.join("&")}` : ""}`);
    return postsPromise.then(response => response);
};
export const markReactionsAsViewed = async (postId: string, userId: string) => {
    return HttpService.putFormData(`/v1/post/${postId}/reactions/viewed`, { creator_id: userId }, true).then(response => response);
};
export const clearPostLoginStoragePrefs = () => {
    deleteStorageItem(SHOULD_WHITELIST_USER_AFTER_LOGIN_KEY);
    sessionStorage.removeItem(SHOULD_SHOW_REACTION_ON_LOGIN);
    sessionStorage.removeItem(USE_BTN_ON_REACTION_LOGIN);
};
export const getMemberById = async (id: string) => {
    return HttpService.getWithAPIKey(`/v1/member/${id}`).then(response => response.member);
};
export const generateOTP = async (data: any) => {
    try {
        await HttpService.postFormData("/v1/mfa/generate/otp", data);
        ToastService.success("OTP sent successfully.");
    } catch (error: any) {
        ToastService.alertableError("Error generating OTP", error);
        return error;
    }
};
export const getMFATypeByUser = async (userId: string) => {
    return HttpService.get(`/v1/user/${userId}/mfa/type`);
};
export const getUserInfo = async (userId: string) => {
    return HttpService.get(`/v1/user/${userId}/info`).then(response => response.user);
};
export const getInboxCountInfo = async () => {
    return HttpService.get(`/v1/dashboard/inbox/count`).then(response => response);
};
export const getUserDetails = async (userId: string) => {
    return HttpService.getWithAPIKey(`/v1/user/${userId}/info`).then(response => response.user);
};
export const generateSignedUrlForFileKey = async (fileKey: string) => {
    return HttpService.postFormData(`/v1/generate/sign/url`, { key: fileKey }).then(response => response.data.url);
};
export const getReceivedEvents = async (page: number, filter = "", labelIds: string, dateFilter = "", searchAttributes?: InboxSearch) => {
    return HttpService.postFormData(`/v1/dashboard/inbox?page=${page}&filter=${filter}&dateFilter=${dateFilter}&labelIds=${labelIds}`, searchAttributes).then(response => response);
};
export const getWelcomePosts = async () => {
    return HttpService.get(`/v1/dashboard/posts/welcome`).then(response => response);
};
export const getPajamaEventByCreatorId = async (creatorId: string) => {
    return HttpService.get(`/v1/event/persona/${creatorId}`).then(response => response.event);
};
export const getPersonasByUserId = async () => {
    return HttpService.get("/v1/persona/retrieve").then(response => response.personas);
};
export const getCreatedEmovids = async (
    page: number,
    sortDescending: boolean,
    sortField: string,
    labelIds: string,
    filter: string = "",
    searchAttributes?: ICreatedVideosSearch
) => {
    return HttpService.postFormData(
        `/v1/dashboard/events/created?page=${page}&sort=${sortDescending ? "-" : ""}${sortField}&filter=${filter}&labelIds=${labelIds}`,
        searchAttributes,
        false
    ).then(response => response.created_events);
};
export const getUserSearches = async (userId: string) => {
    return HttpService.get(`/v1/user/searches/${userId}`).then(response => response.searches);
};
export const signupUserInPrefineryWithPromo = (email: string, name: string, promoCode: string) => {
    const queryParams = new URLSearchParams();
    queryParams.append("email", email);
    queryParams.append("first_name", (name || "").split(" ")[0]);
    queryParams.append("last_name", (name || "").split(" ")[1] || "");
    queryParams.append("utm_campaign", promoCode || "");
    queryParams.append("custom_var1", promoCode || "");
    const whitelistUrl = `https://i.prefinery.com/projects/sz5cvnhx/users/instant?${queryParams.toString()}`;
    let iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.width = "730px";
    iframe.style.height = "730px";
    iframe.src = whitelistUrl;
    document.body.appendChild(iframe);
};
export const whitelistUser = async (email: string, name: string, promoCode: string) => {
    try {
        const response = await HttpService.postFormData(`/v1/whitelisted/email/create`, { email, name, utm_campaign: promoCode }, false, true).then(response => response.email);
        signupUserInPrefineryWithPromo(email, name, promoCode);
        return response;
    } catch (error: any) {
        return error;
    }
};
export const randomBgColor = (): string => {
    var x = Math.floor(Math.random() * 192) + 64;
    var y = Math.floor(Math.random() * 192) + 64;
    var z = Math.floor(Math.random() * 192) + 64;
    return "rgb(" + x + "," + y + "," + z + ")";
};
export const nameForUser = (user: IUserAttributes): string => {
    return user?.name || user?.email?.split("@")[0] || "";
};
export const getUserLabels = async () => {
    return HttpService.get(`/v1/user/labels`).then(response => response.labels);
};
export const createUserLabel = async (formData: any) => {
    return HttpService.postFormData(`/v1/label/create`, formData).then(response => response.label);
};
export const assignUserLabelToPost = async (formData: any) => {
    return await HttpService.postFormData(`/v1/label/assign`, formData);
};
export const deleteUserLabels = async (labelId: string) => {
    return HttpService.delete(`/v1/label/${labelId}/delete`);
};
export const getUserPrompts = async (userId: string) => {
    return HttpService.get(`/v1/user/${userId}/prompts`).then(response => response.prompts);
};
export const updateUserPrompt = async (userId: string, prompt: string) => {
    return HttpService.postFormData(`/v1/user/prompts/update`, { user_id: userId, prompt }, false, true).then(response => response.prompts);
};
export const getUserAiAnalysisPrompts = async (userId: string) => {
    return HttpService.get(`/v1/user/${userId}/analysis/prompts`).then(response => response.prompts);
};
export const updateUserAiAnalysisPrompt = async (userId: string, prompt: string) => {
    return HttpService.postFormData(`/v1/user/analysis/prompts/update`, { user_id: userId, prompt }, false, true).then(response => response.prompts);
};
export const upgradeTrialSubscription = async (duration: string, utmParams: any) => {
    return HttpService.postFormData(`/v1/subscription/trial/upgrade`, { duration, utm_params: utmParams }).then(response => response);
};
export const isEmail = (email: string) => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
};
export const getEncryptionKeys = async () => {
    return new Promise(async (resolve, reject) => {
        const path = "/v1/keys";
        try {
            const headers = {
                authorization: `Basic ${API_KEY}`
            };
            const response: any = await axios({
                method: "GET",
                url: BACKEND_URL + path,
                headers
            });
            const keys = (response.data.keys || []).reduce((acc: any, key: any) => {
                const type = key.key_type === "OLD" ? "old" : "current";
                acc[type] = key.key;
                return acc;
            }, {});
            HttpService.encryptionKey = keys.current;
            return resolve(keys);
        } catch (error: any) {
            return reject(error);
        }
    });
};
export const deleteVideo = async (key: string) => {
    return HttpService.delete("/v1/video/delete", { key }, true);
};
export const createResponse = async (eventId: string, userId: string, isAudio: boolean, title: string) => {
    return HttpService.postFormData(
        `/v1/post/create`,
        {
            creator_id: userId,
            event_id: eventId,
            post_type: "VIDEO",
            is_active: true,
            send_copy: false,
            title,
            is_audio: isAudio,
            urls: []
        },
        false,
        true
    ); // create post
};
export const useUnviewedCountQuery = (options: UseQueryOptions<{ count: number }> = {}) => {
    return useQuery<{ count: number }>(["unviewed_count"], () => HttpService.get(`/v1/post/unviewed/count`), options);
};
export const generateUniqueNumberWithoutUUID = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
export const isValidEmail = (email: string) => {
    return EMAIL_REGEX.test(email);
};
export const getHTMLTextForLogoUrl = (linkToUrl: string, logoUrl: string, isCoBrandLogo?: boolean): string => {
    return `<a href="${linkToUrl}">
    <img
        border="0"
        style="margin-left: 10px;height: ${isCoBrandLogo ? "16px" : "20px"}; margin-top: ${isCoBrandLogo ? "5px" : "4px"};"
        data-proportionally-constrained="true"
        data-responsive="true"
        src="${logoUrl}"
    />
</a>`;
};
export const getHTMLTextForSharing = async (
    url: any,
    isSecureEvent: boolean,
    eventUrl: string,
    emovidTranscriptViewPath: string,
    duration: string,
    aiSummary: string,
    creatorName: string,
    notesPath: string,
    coBrandLogoUrl: string,
    coBrandNavUrl: string
) => {
    let thumbnailUrl = url;
    let imageSize = null;
    let titleText = `A ${formattedSeconds(parseInt(duration))} Emovid message from ${creatorName}`;
    if (!isSecureEvent) {
        imageSize = await getImageMeta(url);
        if (imageSize?.width > 460) thumbnailUrl = await getEmailThumbnailImage(url);
        else thumbnailUrl = url;
    } else thumbnailUrl = SECURE_IMAGE_URL;
    return `
        <div style="margin-bottom: 5px">
            <a href="${eventUrl}" style="cursor: pointer;">${titleText}</a>
            <br />
            <br />
            <a href="${eventUrl}" style="cursor: pointer;"><img src="${thumbnailUrl}" alt="${titleText}" style="display: block; max-width: 450px; max-height: 450px;"/></a>
            ${!isSecureEvent && aiSummary ? `<br /><div ${imageSize?.width ? `style="max-width: ${imageSize?.width}px"` : ""}><b>AI Summary:</b> ${aiSummary}</div>` : ""}
            <br />
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 450px">
                <tbody>
                    <tr>
                        <td valign="middle" align="left" style="vertical-align: middle;">
                            ${notesPath ? `<a href="${notesPath}" style="cursor: pointer;">Notes</a>&nbsp;<span>|</span>&nbsp;` : ""}
                            <a href="${emovidTranscriptViewPath}" style="cursor: pointer;">Full transcript</a>
                        </td>
                        <td valign="middle" align="right" style="vertical-align: middle;"></td>
                    </tr>
                </tbody>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 450px">
                <tbody>
                    <tr>
                        <td valign="middle" align="left" style="vertical-align: middle;"></td>
                        <td valign="middle" align="right" style="vertical-align: middle;">
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td valign="middle" align="right" style="vertical-align: middle; color: #999;font-size: 10px;">sent via </td>
                                        <td valign="middle" style="height: 20px; vertical-align: middle; padding-right: 10px;">
                                            ${getHTMLTextForLogoUrl(window.location.origin, "https://d109vobnb8vsqr.cloudfront.net/images/email_logo.png")}
                                        </td>
                                        ${
                                            coBrandLogoUrl
                                                ? `<td valign="middle" style="vertical-align: middle; border-left: 1px solid #ccc">
                                            ${getHTMLTextForLogoUrl(coBrandNavUrl, coBrandLogoUrl, true)}
                                        </td>`
                                                : ""
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    `;
};
export const getHTMLForSharing = async (
    url: any,
    isSecureEvent: boolean,
    eventUrl: string,
    emovidTranscriptViewPath: string,
    duration: string,
    aiSummary: string,
    creatorName: string,
    notesPath: string,
    coBrandLogoUrl: string,
    coBrandNavUrl: string
) => {
    const html = await getHTMLTextForSharing(url, isSecureEvent, eventUrl, emovidTranscriptViewPath, duration, aiSummary, creatorName, notesPath, coBrandLogoUrl, coBrandNavUrl);
    return window.ClipboardItem ? new Blob([html], { type: "text/html" }) : html;
};
export const isEmovidDomainUser = (email: string = "") => {
    let emailDomain = (email || "").split("@").pop() || "";
    let isWhitelistedDomain = emailDomain.indexOf("emovid.com") >= 0;
    return isWhitelistedDomain;
};
export const hasCoBrandLogo = (authContext: AuthData): boolean => {
    return !!authContext.user?.coBrandLogoUrl;
};
export const getPostUrl = (
    url: string,
    urlType: string,
    duration: string = "",
    videoDimensions: string = "",
    hasModifiedBackground: boolean = false,
    loggedOutCreatorEmail: string = ""
) => {
    const fileName = url ? url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf(".")) : "";
    const fileExtension = url ? url.substr(url.lastIndexOf(".") + 1) : "";
    const urlObject: any = {
        url,
        url_type: urlType,
        metadata: {
            filename: fileName,
            extension: fileExtension
        }
    };
    if (urlType === "FILE") {
        urlObject.metadata.duration = duration;
        urlObject.metadata.dimensions = videoDimensions;
        urlObject.metadata.has_modified_background = hasModifiedBackground;
        if (loggedOutCreatorEmail) urlObject.metadata.logged_out_creator_email = loggedOutCreatorEmail;
    }
    return urlObject;
};
export const createdVideosPathWithCategory = (searchParams: URLSearchParams) => {
    let popupMode = searchParams.get("popup_mode") === "true";
    if (searchParams.get("selected_filter")) return `${Paths.createdVideos}?selected_filter=${searchParams.get("selected_filter")}${popupMode ? "&popup_mode=true" : ""}`;
    return Paths.createdVideos + (popupMode ? "?popup_mode=true" : "");
};
export const formattedStatus = (input: string): string => {
    input = input.toLowerCase();
    return input[0].toUpperCase() + input.substring(1);
};
export const formattedDateTime = (date: Date, showTime = false) => {
    if (!date) return null;
    return moment(date)
        .utcOffset(-new Date().getTimezoneOffset())
        .format(showTime ? "MM/DD/YYYY, h:mm a" : "MM/DD/YYYY");
};
export const formattedTimeSince = (dateValue: string | Date, showTime = false) => {
    if (!dateValue) return "";
    let date = new Date(dateValue);
    let millisecondsSinceDate = new Date().valueOf() - date.valueOf();
    let seconds = Math.floor(millisecondsSinceDate / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return formattedDateTime(date, showTime); // years

    interval = seconds / 2592000;
    if (interval > 1) return formattedDateTime(date, showTime); // months

    interval = seconds / 86400;
    if (interval > 7) return formattedDateTime(date, showTime);
    if (interval > 1) return `${Math.floor(interval)} day${interval > 2 ? "s" : ""} ago`;

    interval = seconds / 3600;
    if (interval > 1) return `${Math.floor(interval)} hour${interval > 2 ? "s" : ""} ago`;

    interval = seconds / 60;
    if (interval > 1) return `${Math.floor(interval)} minute${interval > 2 ? "s" : ""} ago`;

    return `${Math.floor(seconds)} second${seconds > 1 ? "s" : ""} ago`;
};
export const secondsFormatted = (seconds: number, roundSeconds = false): string => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor((seconds % 3600) % 60);
    if (roundSeconds) {
        if (s > 0 && s < 30) s = 0;
        if (s > 30) s = 30;
        m++;
    }
    let hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
    let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    let components = [];
    if (hDisplay) components.push(hDisplay);
    if (mDisplay) components.push(mDisplay);
    if (sDisplay) components.push(sDisplay);
    return components.join(", ");
};
export const formattedTimeSaved = (minutes: number = 0): string => {
    let days = Math.floor(minutes / 1440);
    if (days > 0) {
        let remaining_min = minutes % 1440;
        let hrs = Math.floor(remaining_min / 60);
        return `${days < 10 ? "0" : ""}${days}:${hrs < 10 ? "0" : ""}${hrs}`;
    }
    let h = Math.floor(minutes / 60);
    let m = Math.round(minutes % 60);
    if (m === 60) {
        m = 0;
        h++;
    }
    return `${h < 10 ? "0" : ""}${h}:${m < 10 ? "0" : ""}${m}`;
};
export const formattedDateTimeSinceViewed = (dateValue: string | Date | undefined) => {
    if (!dateValue) return "-";
    let date = new Date(dateValue);
    let millisecondsSinceDate = new Date().valueOf() - date.valueOf();
    let seconds = Math.floor(millisecondsSinceDate / 1000);
    let interval = seconds / 31536000;

    interval = seconds / 86400; // days
    if (interval > 365) return moment(date).format("MMM DD, YYYY");
    if (interval > 1) return moment(date).format("MMM DD, h:mm a");

    interval = seconds / 3600;
    if (interval > 1) return `${Math.floor(interval)} hr${interval > 2 ? "s" : ""} ago`;

    interval = seconds / 60;
    if (interval > 1) return `${Math.floor(interval)} min${interval > 2 ? "s" : ""} ago`;

    return `${Math.floor(seconds)} sec${seconds > 1 ? "s" : ""} ago`;
};
export const getUrl = (url: string, urlType: string) => {
    const fileName = url ? url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf(".")) : "";
    const fileExtension = url ? url.substr(url.lastIndexOf(".") + 1) : "";
    return {
        url,
        url_type: urlType,
        metadata: {
            filename: fileName,
            extension: fileExtension
        }
    };
};
export const determineNavType = (path: string): number => {
    switch (path) {
        case Paths.resetPassword:
        case Paths.updatePassword:
            return NavType.forgotPassword;
        default:
            return NavType.default;
    }
};
export const getErrorMessageFromJSON = (error: Error) => {
    try {
        return error ? JSON.parse(error.message).message : "";
    } catch (e) {
        return "";
    }
};
export const maskEmail = (value: string = "") => {
    const [name, domain] = value.split("@");
    const len = name.length;
    const maskedName = name[0] + "*".repeat(len - 2) + name[len - 1];
    const maskedEmail = maskedName + "@" + domain;
    return maskedEmail;
};
export const maskPhoneNumber = (value: string = "") => {
    const len = value.length;
    return value[0] + value[1] + value[2] + "*".repeat(len - 4) + value[len - 1];
};
export const replaceWithBr = (value: string) => {
    return value.replace(/\n/g, "<br />");
};
export const setVideoAndThumbnailUrlsForPost = (post: IPostResponseAttributes) => {
    post.video_url = (post.urls || []).filter((url: IPostUrl) => url.url_type === "FILE")[0]?.url || "";
    if (post.original_urls) post.authenticity_type = AuthenticityType.AuthenticityTypeFace;
    else {
        let hasModifiedBg = ((post.urls || []).filter((url: IPostUrl) => url.url_type === "FILE")[0]?.metadata || {}).has_modified_background || false;
        post.authenticity_type = hasModifiedBg ? AuthenticityType.AuthenticityTypeBody : AuthenticityType.AuthenticityTypeUnmodified;
    }
    let is_file_upload = ((post.urls || []).filter((url: IPostUrl) => url.url_type === "FILE")[0]?.metadata || {}).is_file_upload;
    if (post?.is_audio || is_file_upload) post.authenticity_type = AuthenticityType.AuthenticityTypeNone;
    post.logged_out_creator_email = ((post.urls || []).filter((url: IPostUrl) => url.url_type === "FILE")[0]?.metadata || {}).logged_out_creator_email;
    if (post.logged_out_creator_email) post.authenticity_type = AuthenticityType.AuthenticityTypeLoggedOutCreator;
    let email_thumbnail_urls = (post.urls || []).filter((url: IPostUrl) => url.url_type === "EMAIL_THUMBNAIL");
    if (email_thumbnail_urls.length) post.email_thumbnail_url = email_thumbnail_urls[0].url;
    else post.email_thumbnail_url = (post.urls || []).filter((url: IPostUrl) => url.url_type === "THUMBNAIL")[0]?.url || "";
    post.thumbnail_url = (post.urls || []).filter((url: IPostUrl) => url.url_type === "THUMBNAIL")[0]?.url || "";
    post.gif_url = (post.urls || []).filter((url: IPostUrl) => url.url_type === "GIF")[0]?.url || "";
};
export const getTextWidth = (text: string, font: string = "") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
        context.font = font || getComputedStyle(document.body).font;
        return context.measureText(text).width;
    } else return 0;
};
export const updatePostPageViews = (postId: string, userId: string) => {
    if (postId) {
        HttpService.putFormData("/v1/post/update/page/views", { post_id: postId, user_id: userId }, true);
    }
};
export const updatePostVideoViews = (postId: string, userId: string) => {
    if (postId) {
        HttpService.putFormData("/v1/post/update/video/views", { post_id: postId, user_id: userId }, true);
    }
};
export const updatePostViewedPercent = (postId: string, userId: string, viewedPercent: number) => {
    if (postId && userId) {
        HttpService.putFormData("/v1/post/update/percent/viewed", { post_id: postId, user_id: userId, viewed_percent: viewedPercent }, true);
    }
};
export const checkPathMatches = (paths: string[], location: string) => {
    let match = false;
    paths.forEach(path => {
        if (matchPath(path, location)) match = true;
    });
    return match;
};
export const formatAiTextForUsageGuide = (inputText: string) => {
    let matches;
    let bulletPoints = [];
    let outputText = `${inputText}`;
    outputText = outputText.replace(/\*\*[\n]*/g, "");
    let regex = /([0-9]+\.)(.*?)(?:\n(?!\d+\.)(.*))?(?=\n\d+\.|\n*$)/gm;
    while ((matches = regex.exec(outputText)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (matches.index === regex.lastIndex) regex.lastIndex++;

        bulletPoints.push({ match: matches[0], text: matches[2] });
    }
    bulletPoints.forEach((bulletPoint, index) => {
        if (index === 0) outputText = outputText.replace(bulletPoint.match, `<ol class="usage-list"><li class="usage-list-item">${bulletPoint.match}</li>`);
        else if (index === bulletPoints.length - 1) outputText = outputText.replace(bulletPoint.match, `<li class="usage-list-item">${bulletPoint.match}</li></ol>`);
        else outputText = outputText.replace(bulletPoint.match, `<li class="usage-list-item">${bulletPoint.match}</li>`);
    });
    return outputText;
};
export const formatAiAnalysisText = (inputText: string) => {
    if (!inputText) return "";
    let outputText = `${inputText}`;
    outputText = outputText
        .replace(/\*\*(.*?)\*\*/gm, `<strong class="text-white">$1</strong>`)
        .replace(/<\/strong>\n\n/gm, "</strong>\n")
        .replace(/- Total/gm, "\n- Total")
        .replace(/<\/strong>\n\n- Total/gm, "</strong>\n- Total");
    return outputText;
};
export const getStorageItem = (storageKey: string) => {
    return localStorage.getItem(storageKey);
};
export const setStorageItem = (storageKey: string, postData: any) => {
    localStorage.setItem(storageKey, postData);
};
export const deleteStorageItem = (storageKey: string) => {
    localStorage.removeItem(storageKey);
};
export const shouldShowThreadToUser = (thread: IEventAttributes, userId: string): boolean => {
    return thread.creator_id === userId || (thread.Members || []).filter(member => member.member_id === userId).length > 0;
};
export const pushTagEvent = (eventName: string, dataLayer: any = {}) => {
    const highlightSessionUrl = getHighlightSessionUrl();
    if (highlightSessionUrl) dataLayer.highlightSessionUrl = highlightSessionUrl;
    amplitude.track(eventName, dataLayer);
    dataLayer = {
        ...dataLayer,
        event: eventName
    };
    TagManager.dataLayer({ dataLayer });
};
export const identifyUserSession = (props: { userId: string; email: string; name: string; isInternal?: boolean }) => {
    H.identify(props.email, {
        id: props.userId,
        name: props.name,
        email: props.email
    });
    let identify = new amplitude.Identify();
    identify.set("user_id", props.userId);
    identify.set("user_name", props.name);
    identify.set("user_email", props.email);
    if (props.isInternal !== undefined) {
        identify.set("is_internal", props.isInternal);
    }
    amplitude.identify(identify);
    amplitude.setUserId(props.email);
};
export const getHighlightSessionUrl = () => {
    return sessionStorage.getItem("highlightSessionUrl");
};
export const logError = (message: string, error?: any, data: any = {}) => {
    console.error(message, error, error?.stack);
    data.user = localStorage.getItem("userData") || "-";
    if (!(error instanceof Error)) {
        data = {
            ...data,
            error
        };
        error = undefined;
    }
    if (!error) {
        error = new Error(message);
    }
    H.consumeError(error, message, data);
    window.newrelic.noticeError(error, {
        ...data,
        message
    });
    pushTagEvent("error", {
        ...data,
        errorMessage: error.message,
        message
    });
};
export const createdEventFromData = (userId: string, event: IEventAttributes, post: IPostAttributes): ICreatedPost => {
    let privacySettings = event.PrivacySettings;
    let createdPost: ICreatedPost = {
        emovid_id: event.id || "",
        post_id: post.id || "",
        emovid_url: "",
        is_draft: post.post_status && post.post_status === "SENT" ? false : true,
        recipients: [],
        subject: post.title || `${post.is_creator ? "" : "Re: "}${event.title}`,
        ai_summary: post.ai_summary,
        transcription_text: post.transcription_text,
        action_items: post.action_items,
        ai_prompt: post.ai_prompt,
        ai_suggestions: post.ai_suggestions,
        my_notes: post.my_notes,
        // @ts-ignore
        date: post["updated_at"],
        views: 0,
        plays: 0,
        post_views: post.PostViews ? post.PostViews.filter(postView => postView.user_id !== userId) : [],
        is_creator: post.is_creator,
        shareableLink: `/emovid/${event.id}/view?show_post=${post.id}`,
        is_favorite: false,
        post_status: post.post_status || PostStatus.DRAFT,
        is_original_post: false,
        is_privacy_settings_enabled: false,
        privacy_settings: privacySettings,
        viewed_percent: 0,
        reactions: post?.Reactions || [],
        thumbnail_url: "",
        email_thumbnail_url: "",
        note: post.note,
        urls: post.urls,
        user_labels: post.PostUserLabels,
        original_urls: post.original_urls,
        unknown_views: post.unknown_views
    };
    if (event && event.Members && createdPost.recipients) {
        for (const memberAttribute of event.Members)
            createdPost.recipients.push({
                id: memberAttribute?.UserDetails?.id || "",
                name: memberAttribute?.UserDetails?.name || "",
                email: memberAttribute?.UserDetails?.email || ""
            });
    }
    createdPost.is_privacy_settings_enabled = !!event.privacy_settings_id;
    return createdPost;
};
export const CanCreatePrivateThreadForPost = (event: IEventAttributes | null, post: IPostResponseAttributes | undefined, authContext: AuthData, isBccMode: boolean) => {
    const emailRecipientUserDetails = JSON.parse(getStorageItem(EmailRecipientUserDetailsStorageKey) || "{}");
    if (isBccMode) {
        let userIsEmovidCreator = authContext.user?.userId === event?.creator_id;
        if (authContext.user?.userId) return post?.creator_id !== authContext.user?.userId && userIsEmovidCreator;
        if (emailRecipientUserDetails?.email) return post?.Creator?.email !== emailRecipientUserDetails?.email && userIsEmovidCreator;
    } else if ((event?.Members || []).length < 2) return false;
    if (authContext.user?.userId) return post?.creator_id !== authContext.user?.userId;
    if (emailRecipientUserDetails?.email) return post?.Creator?.email !== emailRecipientUserDetails?.email;
    return false;
};
export const pushContactUsEvent = (sectonName: string) => {
    pushTagEvent("initiate_contact_us", { page_url: window.location.href, section_title: sectonName });
};
export const isSmallScreen = () => {
    return window.innerWidth < 380;
};
export const getAiSummaryForPost = (post: ICreatedPost) => {
    return post.ai_summary ? `"${post.ai_summary}"` : post.post_status === PostStatus.DRAFT_SPEAKING_NOTES ? "No Video" : "-";
};
export const getSubjectForPost = (post: ICreatedPost) => {
    return (post.post_status === PostStatus.DRAFT_SPEAKING_NOTES ? post.ai_suggestions || post.my_notes || "" : post.subject || "-").replace(/<[^>]+>/g, "");
};
export const isDraftPostMenu = (post: ICreatedPost) => {
    return [PostStatus.DRAFT_SHARABLE_LINK, PostStatus.DRAFT_EDIT_VIDEO, PostStatus.DRAFT_SPEAKING_NOTES].includes(post?.post_status || PostStatus.SENT);
};
export const canEditSecuritySettings = (post: ICreatedPost) => {
    return post.is_creator && ![PostStatus.DRAFT, PostStatus.DRAFT_EDIT_VIDEO, PostStatus.DRAFT_SPEAKING_NOTES].includes(post?.post_status || PostStatus.SENT);
};
export const canSharePost = (post: ICreatedPost) => {
    return (
        post?.post_status === PostStatus.SENT ||
        [PostStatus.DRAFT_EDIT_VIDEO, PostStatus.DRAFT_PREVIEW, PostStatus.DRAFT_SHARABLE_LINK].includes(post?.post_status || PostStatus.SENT)
    );
};
export const capitalizeFirstChar = (s: string) => {
    return s && s[0].toUpperCase() + s.slice(1);
};
export const menuProps = {
    elevation: 0,
    sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            bgcolor: "white",
            color: "#333",
            ml: -0.5,
            mr: 1
        },
        "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "#777",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0
        }
    }
};
export const topRightMenuProps = {
    elevation: 0,
    sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            bgcolor: "white",
            color: "#333",
            ml: -0.5,
            mr: 1
        },
        "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 14,
            right: 0,
            width: 10,
            height: 10,
            bgcolor: "#777",
            transform: "translateX(50%) rotate(45deg)",
            zIndex: 0
        }
    }
};
export const hasUserTrialEnded = (authContext: AuthData): boolean => {
    return (authContext.isTrialUser && authContext?.hasTrialEnded) || false;
};
export const userTrialDaysAboutToEnd = (authContext: AuthData): boolean => {
    if (authContext.isTrialUser) return authContext.trialDaysLeft >= 0;
    return false;
};
export const hasEmailUserTrialEnded = (): boolean => {
    return getStorageItem(EmailRecipientTrialExpiredUserStorageKey) === "true";
};
export const showWarningTrialStrip = (authContext: AuthData): boolean => {
    return hasUserTrialEnded(authContext) || hasEmailUserTrialEnded() || userTrialDaysAboutToEnd(authContext);
};
export const showUpgradeButton = () => {
    return !(window.location.pathname.endsWith(Paths.pricing) || window.location.pathname.endsWith(Paths.trialOffer));
};
export const getNavHeightPx = (authContext: AuthData): number => {
    const heights = !(isDesktop || isTablet) ? NAV_HEIGHTS.mobile : NAV_HEIGHTS.desktop;
    return showWarningTrialStrip(authContext) ? (showUpgradeButton() ? heights.trial : heights.trial_without_button) : heights.normal;
};
export const getNavHeight = (authContext: AuthData): string => {
    return `${getNavHeightPx(authContext)}px`;
};
export const toDataURL = async (url: string) => {
    return await fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(res => {
                reader.onloadend = () => {
                    res(reader.result);
                };
            });
        });
};
export const checkFileSize = (file: File, maxSizeInMB: number = MAX_FILE_SIZE_IN_MB, suppressError = false) => {
    if (!file) return true;
    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB > maxSizeInMB) {
        if (!suppressError) ToastService.userError(`File size should be less than ${maxSizeInMB}MB`);
        return false;
    }
    return true;
};
export const getEmailThumbnailImage = async (url: any) => {
    if (url) {
        const key = new URL(url).pathname.substring(1);
        const data = await HttpService.postFormData(`/v1/thumbnail/retrieve`, { key, isPublic: true }, false, true);
        return data.image;
    }
};
export const getImageMeta = (url: string): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
        img.onerror = err => reject(err);
        img.src = url;
    });
};
export const blobToBase64 = async (blob: any) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};
export const formattedSeconds = (seconds: number) => {
    let minutes = Math.floor(seconds / 60);
    if (minutes >= 1 && seconds - minutes * 60 > 0) return `${minutes} minute, ${seconds - minutes * 60} second`;
    if (minutes >= 1) return `${minutes} minute`;
    else return `${seconds} second`;
};
export const getIosVersion = () => {
    return ((((window.navigator.userAgent || "").match(/OS (\d)?\d_\d(_\d)?/i) || [])[0] || "0").split("_")[0] || "").replace("OS ", "");
};
export const isLandscape = () => {
    switch ((window.screen.orientation || {}).type) {
        case "landscape-primary":
        case "landscape-secondary":
            return true;
        case "portrait-secondary":
        case "portrait-primary":
        default: // The orientation API isn't supported in this browser
            return false;
    }
};

export const getCsrfToken = () => {
    const csrfToken = decodeURIComponent(
        document?.cookie
            ?.split("; ")
            ?.find(row => row.startsWith("_csrf="))
            ?.split("=")[1] as string
    );
    return csrfToken;
};

export const isIOSPlatform = () => {
    return Capacitor.getPlatform() === "ios";
};

export const isAndroidPlatform = () => {
    return Capacitor.getPlatform() === "android";
};

export const isNativePlatform = () => {
    return Capacitor.isNativePlatform();
};

export const getInsetTopHeight = () => {
    return isNativePlatform() ? parseInt(getComputedStyle(document.documentElement).getPropertyValue("--inset-top").replace("px", "")) : 0;
};

export const getDeviceInfo = async () => {
    return await Device.getInfo();
};

export const openURLInBrowser = async (path: string) => {
    if (isNativePlatform() && path) {
        await Browser.open({ url: `${DOMAIN_URL}${path}` });
    }
};

export const isPortrait = () => {
    switch ((window.screen.orientation || {}).type) {
        case "landscape-primary":
        case "landscape-secondary":
            return false;
        case "portrait-secondary":
        case "portrait-primary":
        default: // The orientation API isn't supported in this browser
            return true;
    }
};
export const dimensionsMismatchOrientation = (width: number, height: number) => {
    return (isPortrait() && width > height) || (isLandscape() && width < height);
};
export const notesHtmlForPost = (post: IPostResponseAttributes) => {
    let notesText = post?.note || "";
    notesText = notesText.split("<a ").join("<a target='_blank' ");
    if (new Date(post.created_at) < new Date("2024-08-06T06:57:27.121Z")) return notesText.replace("color: rgb(255, 255, 255)", "color: #aaaaaa");
    return notesText;
};
export const titleForPath = (path: string) => {
    if (path.startsWith("/emovid") && path.endsWith("/record")) return "Emovid - Record video";
    else if (path.startsWith("/emovid") && path.endsWith("/edit-video")) return "Emovid - Edit video";
    else if (path.startsWith("/emovid") && path.endsWith("/preview")) return "Emovid - Preview";
    else if (path.startsWith(Paths.howItWorks)) return "Emovid - How it works";
    else if (path.startsWith(Paths.useCases)) return "Emovid - Discover Video Messaging Use Cases for Your Business";
    else if (path.startsWith(Paths.pricing)) return "Emovid - Pricing";
    else if (path.startsWith(Paths.contactSales)) return "Emovid - Contact Us";
    else if (path.startsWith(Paths.getSupport)) return "Emovid - Get Support";
    else if (path.startsWith(Paths.aboutUs)) return "Emovid - About Us";
    else if (path.startsWith(Paths.usageGuide)) return "Emovid - Discover Personalized AI Use Cases AI Usage Guide";
    else if (path.startsWith(Paths.signMeUp)) return "Emovid - Business Sign Up";
    else if (path.startsWith(Paths.trialOffer)) return "Emovid - Trial Upgrade Request";
    else if (path.startsWith(Paths.terms)) return "Emovid - Terms of Use";
    else if (path.startsWith(Paths.affiliate)) return "Emovid - Affiliate Marketing Agreement";
    else if (path.startsWith(Paths.privacyPolicy)) return "Emovid - Privacy Policy";
    else if (path.startsWith(Paths.press)) return "Emovid - Press and Industry";
    else if (path.startsWith(Paths.amazonGiftCard)) return "Emovid - Amazon Gift Card";
    else if (path.startsWith(Paths.createdVideos)) return "Emovid - Created Emovids";
    else if (path.startsWith(Paths.resources)) return "Emovid - Resources";
    else if (path.startsWith(Paths.dealRegistration)) return "Emovid - Deal Registration";
    else if (path.startsWith(Paths.myInbox) || path.startsWith(Paths.dashboard)) return "Emovid - My Inbox";
    else if (path.startsWith(Paths.myAccount)) return "Emovid - My Account";
    else if (path.startsWith(Paths.sendFeedback)) return "Emovid - Share Feedback";
    else if (path.startsWith(Paths.newsLetters.replace(":newsletterId", "jeffrey-hayzelett"))) return "Why I Switched to Video Messaging: Jeffrey Hayzlett's Emovid Success Story";
    else if (path.startsWith(Paths.testimonials.replace(":testimonialId", "jeffrey-hayzelett")))
        return "Video Messaging Revolution: C-Suite Leader Jeffrey Hayzlett on Transforming Business Communication | Emovid Insights";
    return APP_TITLE;
};
export const isFullscreen = (): boolean => {
    return !!document.fullscreenElement;
};
export const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
        document.body.requestFullscreen();
    } else {
        document.exitFullscreen();
    }
};
